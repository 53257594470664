import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as RightArrow } from 'Assets/category-right-arrow.svg';
import { ReactComponent as LeftArrow } from 'Assets/category-left-arrow.svg';
import { dataFetcher, endpoints } from 'Api';
import { ProfilePage, ProjectorShimmer } from 'Components';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import ImageGroup from 'Components/ImageGroup/ImageGroup';
import styles from './index.module.css';
import { WhatsNew } from './Component/WhatsNew/whatsNew';
import { ImageBanner } from './Component/ImageBanner/imageBanner';
import { AlbumBanner } from './Component/AlbumBanner/albumBanner';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important'
  }
})(MuiCircularProgress);

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const isTrueValue = true;

const CustomRightArrow = ({ onClick, layoutType }) => ((
  <RightArrow
    fontSize="large"
    onClick={onClick}
    aria-label="Go to next slide"
    className={layoutType === 3 || layoutType === 4 ? 'react-multiple-carousel__arrow react-multiple-carousel__arrow--right layout_arrow_category' : 'react-multiple-carousel__arrow react-multiple-carousel__arrow--right custom_arrow_category'}
  />
));

const CustomLeftArrow = ({ onClick, layoutType }) => ((
  <LeftArrow
    onClick={onClick}
    aria-label="Go to next slide"
    className={layoutType === 3 || layoutType === 4 ? 'react-multiple-carousel__arrow react-multiple-carousel__arrow--left layout_arrow_category' : 'react-multiple-carousel__arrow react-multiple-carousel__arrow--left custom_arrow_category'}
  />
));

export const MyProfile = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-script-url
  window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const { setAppSnackbar, userDetails, langText } = useContext(AppContext);
  // const { profileID } = useParams();
  const profileID = userDetails.myId;
  const profileText = langText?.viewProjector;
  const [selectedCategoryID, setSelectedCategoryID] = useState(undefined);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profileName, setProfileName] = useState(undefined);
  const [profileHeroImage, setProfileHeroImage] = useState(undefined);
  const [resumeWatching, setResumeWatching] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [orderedData, setOrderedData] = useState(undefined);
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState(undefined);
  const [showArrow, setArrows] = useState(false);
  const [layoutType, setLayoutType] = useState(1);
  const [layoutData, setLayoutData] = useState(undefined);
  const [haveData, checkData] = useState(undefined);
  const [categoryStyleId, setCategoryStyleId] = useState(undefined);
  const [thumbnail, setThumbnail] = useState(undefined);
  const { isValidating: gettingFriendProfile, mutate: getFriendProfile } = useSWR([endpoints.getUserContents, profileID], {
    fetcher: (url, user_id) => dataFetcher(url, { user_id }),
    onSuccess: ({ success, videos, freind, CurrentlyWatching, playlist, category, data, layouts, layout_type_id, message, category_style_id }) => {
      if (success) {
        if (freind?.firstname) {
          setProfileName(freind?.firstname?.substring(0, 10));
        } else if (freind?.email) {
          setProfileName(freind?.email?.split('@')[0]?.substring(0, 10));
        }
        if (videos?.length) {
          setProfileHeroImage(videos[0]?.thumbnails);
        }
        setOrderedData(data);
        setLayoutData(layouts);
        if (layout_type_id) {
          setLayoutType(parseInt(layout_type_id, 10));
        }
        checkData(data.some((obj) => obj?.content?.length) ? 'yes' : 'no');
        setCategoryStyleId(parseInt(category_style_id, 10));
        // if (CurrentlyWatching?.length) {
        //   setResumeWatching(CurrentlyWatching);
        // }
        // 1 - Large and Square
        // 2 - Large and Rounded
        // 3 - Small and Square
        // 4 - Small and Rounded
        if (playlist?.length) {
          setPlaylists(playlist);
        }
        setCategories(data?.filter(({ content_type }) => content_type === 'Categories')[0]?.content);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: message || 'Oops! Something went wrong' });
      }
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong' });
    },
  });

  const { isValidating: isCategoryLoading, mutate: getCategoryDetails } = useSWR([endpoints.getCategoryDetail, profileID, selectedCategoryID], {
    fetcher: (url, user_id, category_id) => dataFetcher(url, { user_id, category_id }),
    onSuccess: ({ success, videos, message }) => {
      if (success) {
        setSubCategories([{ title: '', videos }]);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: message || 'Oops! Something went wrong' });
      }
      setLoadingCategories(false);
    },
    onError: (error) => {
      setLoadingCategories(false);
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong' });
    },
  });

  const { isValidating: isVideoListLoading, mutate: getVideoList } = useSWR([endpoints.getUserVideoList, profileID], {
    fetcher: (url, user_id) => dataFetcher(url, { user_id }),
    onSuccess: ({ success, data, message }) => {
      if (success) {
        setAllVideos(data);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: message || 'Oops! Something went wrong' });
      }
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong' });
    },
  });

  const { mutate: getMyPausedList } = useSWR([endpoints.getMyPausedList], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, paused, message }) => {
      if (success) {
        setResumeWatching(paused);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: message || 'Oops! Something went wrong' });
      }
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong' });
    },
  });

  const { mutate: clearResumeWatching } = useSWR([endpoints.clearResumeList], {
    fetcher: (url) => dataFetcher(url),
    onSuccess: ({ success, message }) => {
      if (success) {
        getMyPausedList();
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong' });
    },
  });

  useEffect(() => {
    if (selectedCategoryID) {
      setLoadingCategories(true);
      getCategoryDetails();
    }
  }, [selectedCategoryID]);

  useEffect(() => { getFriendProfile(); getVideoList(); getMyPausedList(); }, []);
  return (
    <ProfilePage
      containerClassName={cn({ [styles.pageContainer]: true, [styles.pageContainerVideoBanner]: layoutType === 4 })}
      mainClassName={cn({ [styles.mainContent]: true, [styles.loading]: loading })}
      userName={profileName}
      isProfileHeader
      userProfileLink={Pathname.getFriendProfilePath(profileID)}
      videoBanner={layoutType === 4}
      layoutData={layoutData}
    >
      {(gettingFriendProfile || !profileName) || isCategoryLoading || isVideoListLoading
        ? <ProjectorShimmer />
        : (
          <>
            <div
              className={[styles.heading]}
            >
              <div className={styles.headingBGFade} />
              {/*
                gettingFriendProfile ? (
                  <div className={styles.headingText}>
                    <ProjectorShimmer />
                  </div>
                ) : (
                  layoutType === 1 || !layoutData?.length ? (
                  <> */}
              <div className={styles.headingText}>
                {`${profileName?.trim()}'s Projector`}
                {/* {orderedData?.some((data) => data.content.length < 0) ? null : ' is \'empty\''} */}
              </div>
              {/* </>
                  ) : layoutType === 2 ? (
                    <WhatsNew layoutData={layoutData} orderedData={orderedData} profileName={profileName} layoutType={layoutType} />
                  ) : layoutType === 3 ? (
                    <ImageBanner layoutData={layoutData} orderedData={orderedData} profileName={profileName} layoutType={layoutType} />
                  ) : layoutType === 5 ? (
                    <AlbumBanner layoutData={layoutData} orderedData={orderedData} profileName={profileName} layoutType={layoutType} />
                  ) : null
                )
                  */}
            </div>
            {!!categories?.length && (
              <div className={cn({ [styles.imageGroup]: true, [styles.layoutImageGroup]: (layoutType === 3 || layoutType === 5) && (categoryStyleId === 3 || categoryStyleId === 4), [styles.videoBannerImageGroup]: layoutType === 4 && (categoryStyleId === 3 || categoryStyleId === 4) })}>
                <div className={styles.imageGroupImagesWrapper}>
                  <div
                    onMouseEnter={() => setArrows(true)}
                    onMouseLeave={() => setArrows(false)}
                    className={cn({ [styles.imageGroupImagesContainer]: true, [styles.isGrid]: true })}
                  >
                    <Carousel
                      arrows={showArrow}
                      showDots={false}
                      responsive={responsive}
                      ssr={isTrueValue} // means to render carousel on server-side.
                      autoPlaySpeed={1000}
                      keyBoardControl={isTrueValue}
                      containerClass="carousel-container"
                      dotListClass="custom-dot-list-style"
                      itemClass={categoryStyleId === 3 || categoryStyleId === 4 ? 'category-item' : 'image-item'}
                      customRightArrow={<CustomRightArrow layoutType={layoutType} />}
                      customLeftArrow={<CustomLeftArrow layoutType={layoutType} />}
                    >
                      {categories?.map(({ id, title, icon }, idx) => (
                        <div
                          style={{
                            backgroundImage: `linear-gradient(
                              rgba(0, 0, 0, 0.5),
                              rgba(0, 0, 0, 0.5)
                            ),url(${icon})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            fontWeight: '700',
                          }}
                          className={cn({
                            [styles.imageGroupImageLink]: true,
                            [styles.categoryButton]: true,
                            [styles.active]: selectedCategoryID === id,
                            [styles.textAlignFit]: true,
                            [styles.bannerTypeCategoryButton]: categoryStyleId === 3 || categoryStyleId === 4,
                            [styles.rounded]: categoryStyleId === 2 || categoryStyleId === 4
                          })}
                          key={idx}
                          tabIndex="0"
                          role="button"
                          onKeyPress={undefined}
                          onClick={() => {
                            if (selectedCategoryID === id) {
                              setSelectedCategoryID(undefined);
                            } else {
                              setSelectedCategoryID(id);
                              setSelectedCategoryTitle(title);
                              setThumbnail(icon);
                            }
                            navigate('/innerpage', { state: { title, id, icon, profile_id: profileID } });
                          }}
                        >
                          {title}
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </div>
              </div>
            )}
            {
              selectedCategoryID ? (
                <div className={styles.selectedCategoryButton}>
                  <div className={styles.selectedCategoryTitle}>
                    {selectedCategoryTitle}
                  </div>
                  <div className={styles.closeButtonWrapper}>
                    <CloseIcon
                      className={styles.closeButton}
                      fontSize="small"
                      onClick={() => {
                        setSelectedCategoryID(undefined);
                      }}
                    />
                  </div>
                </div>
              ) : null
            }
            {selectedCategoryID
              ? loadingCategories
                ? (
                  <div className={styles.categoriesLoader}>
                    <ProjectorShimmer />
                  </div>
                )
                : (
                  <>
                    {!!subCategories?.length && (
                      subCategories?.map((subCategory, idx) => (
                        !!subCategory?.videos?.length && (
                          <ImageGroup
                            isGrid
                            videos={subCategory?.videos}
                            key={idx}
                            title={subCategory?.title}
                            catG
                          />
                        )
                      ))
                    )}
                  </>
                )
              : (
                <>
                  {!!resumeWatching?.length && (
                    <ImageGroup
                      title={profileText?.resumeWatching || 'Resume Watching'}
                      videos={resumeWatching}
                      isGrid
                      resumeWatching
                      clearResumeWatching={clearResumeWatching}
                      setLoading={setLoading}
                    />
                  )}

                  {haveData === 'yes' ? (
                    orderedData?.map((data) => (
                      data.content_type === 'Photos Albums' ? (
                        !!data.content?.length && (
                          <ImageGroup
                            isGrid
                            albums={data.content}
                            type="photo_album"
                            title={`${profileName}'s Photo Albums`}
                          />
                        )
                      ) : (
                        !!data.content?.length && (
                          data.content?.map((subdata) => (
                            !!subdata?.videos?.length && (
                              <ImageGroup
                                isGrid
                                videos={subdata?.videos}
                                key={subdata?.id}
                                title={subdata?.title}
                              />
                            )
                          ))
                        )
                      )
                    ))
                  ) : haveData === 'no' ? (
                    <div className={styles.noContentText}>
                      {`${profileName.charAt(0).toUpperCase()}${profileName.slice(1)} hasn't uploaded any contents yet`}
                    </div>
                  ) : null}
                  {!!allVideos?.length && (
                    <ImageGroup
                      title={profileText?.allVideos || 'All Videos'}
                      videos={allVideos}
                      isGrid
                    />
                  )}
                </>
              )}
          </>
        )}
    </ProfilePage>
  );
};
