/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext, useEffect } from 'react';
import { Page } from 'Components';
import { AppContext, AuthContext } from 'Context';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { useSelector, useDispatch } from 'react-redux';
import { setDidCardAdded } from 'store/store';
import Dialog from '@material-ui/core/Dialog';
import styles from './index.module.css';
import { InfoModal } from '../../dashboard/info-modal';
import CardDetails from './CardDetails';
import AddCard from './AddCard/AddCard';

export function ManagePaymentMethods() {
  const { subscriptionDetails, langText, setAppSnackbar, setcardAdded } = useContext(AppContext);
  const { isInActiveUser, gettingSubscription } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const paymentMethodText = langText?.settings?.billPayment?.paymentMethod;
  const purchaseId = subscriptionDetails?.subscription?.purchase_plan_id;
  const dispatch = useDispatch();
  const handleUpdateState = () => {
    dispatch(setDidCardAdded(false));
  };
  const [paymentMethods, setPaymentMethods] = useState([]);
  const didCardAdded = useSelector((state) => state.didCardAdded);

  useEffect(() => {
    if (subscriptionDetails?.paymentMethods) {
      setPaymentMethods(subscriptionDetails?.paymentMethods);
    }
  }, [subscriptionDetails]);
  const handleClose = () => {
    setOpen(false);
  };
  const { isValidating: paymentRetrying, mutate: retryPayment } = useSWR([
    endpoints.retryPayment, purchaseId], {
    fetcher: (url, subscription_id) => dataFetcher(url, { subscription_id }),
    onSuccess: ({ success, message }) => {
      if (success) {
        setAppSnackbar({
          isVisible: true,
          type: 'success',
          message,
        });
        handleClose();
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message,
        });
        handleClose();
      }
      window.location.reload();
    },
    onError: (error) => {
      const responseData = error?.response?.data?.message;
      setAppSnackbar({ isVisible: true, type: 'error', message: (responseData || 'Oops! Something went wrong.') });
    },
  });
  const handleAddNewCard = () => {
    setcardAdded(false);
    setOpen(true);
  };
  return (
    <Page headerTheme={styles.headerTheme}>
            {
        didCardAdded === true && isInActiveUser && (
          <InfoModal
            isVisible={didCardAdded}
            title="Your subscription needs to be renewed"
            content={(
              <pre>
                Projector subscription was paused as your renewal with your
                {'\n'}
                payment method on file was unsuccessful.
                {'\n'}
                Do you wish to renew now?
              </pre>
            )}
            onHide={() => handleUpdateState()}
            continueButton="Not now"
            // isLoading={dataLoading}
            action={() => retryPayment()}
            actionButton="Renew"
            isDelete
            fromPayment="true"
          />
        )
      }
      <div className={styles.main_page}>
        <div className={styles.headings}>

          <div className={styles.primary_heading}>
            {paymentMethodText?.title || 'Manage payment info'}
          </div>
          <div
            className={styles.secondary_heading}
          >
            {paymentMethodText?.description || 'Edit your payment details, add a backup or switch your preferred payment method.'}

          </div>
        </div>
        <div className={styles.main_container}>

          <div className={styles.cardContainer}>

            {paymentMethods?.map(({ card, id, last_attempt }) => (
              <CardDetails
                paymentMethods={paymentMethods}
                brand={card.brand}
                last4={card.last4}
                cardId={id}
                status={last_attempt}
                removedAllowed={subscriptionDetails?.paymentMethods?.length > 1}
                isPreferred={subscriptionDetails?.default_payment_methods === id}
              />
            ))}
            {subscriptionDetails?.paymentMethods?.length <= 1 && (
            <div className={styles.message}>
              {paymentMethodText?.removeValidation || 'To delete payment method, add a new one'}
            </div>
            )}
          </div>

          <div
            className={styles.button_container}
          >
            <button onClick={handleAddNewCard}>
              {paymentMethodText?.addMethod || 'Add new payment method'}
            </button>
          </div>
        </div>

      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <AddCard closeAddCard={handleClose} />

      </Dialog>
    </Page>
  );
}
