/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-indent */
import React, { useContext, useRef, useEffect, useState, useLayoutEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import pt from 'prop-types';
import defaultDpImage from 'Assets/person.svg';
import defaultDpImage_Female from 'Assets/female@2x.png';
import defaultDpImage_Male from 'Assets/male@2x.png';
import PersonIcon from 'Assets/person-profile.svg';
import { ReactComponent as OvalBackground } from 'Assets/oval-background.svg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ReactComponent as DragIcon } from 'Assets/drag.svg';
import newRequestImage from 'Assets/plus@2x.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ReactComponent as RightArrow } from 'Assets/profile-right-arrow.svg';
import { ReactComponent as LeftArrow } from 'Assets/profile-left-arrow.svg';
import AlterImage from 'Assets/alter-image.png';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import { AppContext, AuthContext } from 'Context';
import { Pathname } from 'Routes';
import { PromotionModal } from 'Components/upload-modal';
import { MobileModal } from 'Components/mobileViewModal';
import { baseUrl, axios } from 'Api';
import styles from './index.module.css';
import { Page, Snackbar, ConfirmationModal } from '../../../Components';

const ProfileLink = ({ setProfileDetails, className, onClick, dpURL, gender, isAccessRequestButton, link, name,
  isViewMyContent, id, setdataOfCategory, showRemoveProjectorText, setModalTitle, setProjectorImage, setModal, setProjectorOwner, setProjectorName, setProjectorId, removeProjectorText, isBelowAccessButton }) => (
  <div
  onClick={() => {
    setdataOfCategory && setdataOfCategory();
    setProfileDetails && setProfileDetails({
      firstname: name,
      image: dpURL
    });
  }}
  className={cn({ [styles.profileLink]: true, [className]: className })}
  >

    <div onClick={onClick} style={{ color: 'white' }} className={styles.profileContainer}>
      <div style={{ overflow: 'hidden' }} className={styles.profileImage}>
        {isAccessRequestButton ? (
          <Link
            style={{
              borderRadius: '50%',
              // border: '0.1px solid #707070',
              width: isBelowAccessButton ? '60px' : '85px',
              height: isBelowAccessButton ? '60px' : '85px',
              // padding: '2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              backgroundColor: '#31343E'
            }}
            to={link}
          >
            <img
              className={cn({ [styles.dp]: true, [styles.bottomDp]: isBelowAccessButton })}
              src={newRequestImage || AlterImage}
              alt="Request Access"
              onError={(e) => {
                e.target.src = AlterImage;
              }}
            />
          </Link>
        )
          : isViewMyContent ? (
            <div
              className={`${styles.profileName} ${styles.vewMyContent}`}
            >
              {name}
            </div>
          )
            : !dpURL ? (
              <Link
                style={{
                  borderRadius: '50%',
                  border: '2px solid white',
                  width: '173px',
                  height: '173px',
                  // padding: '2rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
                to={link}
              >

                <img
                  className={styles.dp}
                  src={
                    (gender === 'female'
                      ? defaultDpImage_Female
                      : gender === 'male'
                        ? defaultDpImage_Male
                        : gender === 'common'
                          ? PersonIcon
                          : defaultDpImage || AlterImage)
                  }
                  alt={name}
                  onError={(e) => {
                    e.target.src = AlterImage;
                  }}
                />
              </Link>
            ) : (
              <Link
                style={{
                  borderRadius: '50%',
                  border: '2px solid white',
                  width: '173px',
                  height: '173px',
                  // padding: '2rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
                to={link}
              >
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={dpURL || AlterImage}
                  onError={(e) => {
                    e.target.src = AlterImage;
                  }}
                />
              </Link>
            )}
      </div>
      {!isViewMyContent ? <div className={cn({ [styles.profileName]: true, [styles.bottomName]: isBelowAccessButton })}>{name}</div> : null}
    </div>
    {id && showRemoveProjectorText && (
      <div
        style={{ color: 'red' }}
        className={cn({
          [styles.profileName]: true,
          [styles.removeProjectorText]: true,
        })}
        onClick={() => {
          // deleteUser();
          setProjectorId(id);
          setProjectorName(name);
          setModalTitle(`Are you sure you want to remove ${name?.trim()}'s projector?`);
          setModal(true);
        }}
      >
    {removeProjectorText}
      </div>
    )}
  </div>
);

ProfileLink.defaultProps = {
  className: undefined,
  dpURL: undefined,
  gender: undefined,
  isAccessRequestButton: false,
  name: undefined
};

ProfileLink.propTypes = {
  className: pt.string,
  dpURL: pt.string,
  gender: pt.string,
  isAccessRequestButton: pt.bool,
  link: pt.string.isRequired,
  name: pt.string
};
const ProfileLinkForDnd = ({
  draggableProvided,
  email,
  lastname,
  setProfileDetails,
  className,
  onClick,
  dpURL,
  gender,
  isAccessRequestButton,
  link,
  name,
  isViewMyContent,
  id,
  setdataOfCategory,
  showRemoveProjectorText,
  setModalTitle,
  setProjectorImage,
  setModal,
  setProjectorOwner,
  setProjectorName,
  setProjectorId,
  removeProjectorText,
  isBelowAccessButton,
}) => (
  <div
    style={{ marginBottom: '15px' }}
    onClick={() => {
      setdataOfCategory && setdataOfCategory();
      setProfileDetails && setProfileDetails({
        firstname: name,
        image: dpURL,
      });
    }}
    className={cn({ [styles.profileLinkDnd]: true, [className]: className })}
  >
    <div onClick={onClick} style={{ color: 'white' }} className={styles.profileContainerDnd}>
    <div className={styles.dragIconContainer} {...draggableProvided.dragHandleProps}>
      <DragIcon className={styles.dragIcon} />
    </div>
      <div style={{ overflow: 'hidden' }} className={styles.profileImage}>

        {isAccessRequestButton ? (
          // Access request button
          <Link
            style={{
              borderRadius: '50%',
              width: isBelowAccessButton ? '60px' : '85px',
              height: isBelowAccessButton ? '60px' : '85px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              backgroundColor: '#31343E',
            }}
            to={link}
          >
            <img
              className={cn({ [styles.dp]: true, [styles.bottomDp]: isBelowAccessButton })}
              src={newRequestImage || AlterImage}
              alt="Request Access"
              onError={(e) => {
                e.target.src = AlterImage;
              }}
            />
          </Link>
        ) : isViewMyContent ? (
          // View content
          <div className={`${styles.profileNameDnd} ${styles.vewMyContent}`}>
            {name}
          </div>
        ) : !dpURL ? (
          // Default profile image
          <Link
            style={{
              borderRadius: '50%',
              border: '2px solid white',
              width: '100px',
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
            to={link}
          >
            <img
              className={styles.dp}
              src={
                gender === 'female'
                  ? defaultDpImage_Female
                  : gender === 'male'
                  ? defaultDpImage_Male
                  : gender === 'common'
                  ? PersonIcon
                  : defaultDpImage || AlterImage
              }
              alt={name}
              onError={(e) => {
                e.target.src = AlterImage;
              }}
            />
          </Link>
        ) : (
          // Custom profile image
          <Link
            style={{
              borderRadius: '50%',
              border: '2px solid white',
              width: '100px',
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
            to={link}
          >
            <img
              style={{ width: '100%', height: '100%' }}
              src={dpURL || AlterImage}
              onError={(e) => {
                e.target.src = AlterImage;
              }}
            />
          </Link>
        )}
      </div>
      {/* Display name, lastname, and email */}
      {!isViewMyContent ? (
        <>
          <div className={cn({ [styles.profileNameDnd]: true, [styles.bottomName]: isBelowAccessButton })}>
            {name}
          </div>
          <div className={cn({ [styles.profileNameDnd]: true, [styles.bottomName]: isBelowAccessButton })}>
            {lastname}
          </div>
          <div className={cn({ [styles.profileNameDnd]: true, [styles.bottomName]: isBelowAccessButton })}>
            {email}
          </div>
        </>
      ) : null}
      {/* Remove projector text */}
      {id && showRemoveProjectorText && (
        <div
          style={{ color: 'red' }}
          className={cn({
            [styles.profileName]: true,
            [styles.removeProjectorText]: true,
          })}
          onClick={() => {
            // deleteUser();
            setProjectorId(id);
            setProjectorName(name);
            setModalTitle(`Are you sure you want to remove ${name?.trim()}'s projector?`);
            setModal(true);
          }}
        >
          {removeProjectorText}
        </div>
      )}
    </div>
  </div>
);
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
    slidesToSlide: 6, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
};
const isTrueValue = true;

const CustomRightArrow = ({ onClick }) => ((
  <RightArrow
    fontSize="large"
    onClick={onClick}
    aria-label="Go to next slide"
    className={`${styles.customRightArrow} react-multiple-carousel__arrow react-multiple-carousel__arrow--right custom_arrow_profile`}
  />
));

const CustomLeftArrow = ({ onClick }) => ((
  <LeftArrow
    onClick={onClick}
    aria-label="Go to next slide"
    className={`${styles.customLeftArrow} react-multiple-carousel__arrow react-multiple-carousel__arrow--left custom_arrow_profile`}
  />
));

export const Access = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-script-url
  window.location.href = 'javascript: $zopim.livechat.button.show()';
  const defaultSnackbar = { isVisible: false, message: '', type: '' };
  const { userConnections, loadingForAccess, getUserConnections, userDetails,
    langText, setHelpNeed, setdataOfCategory, setNeedToShowETA,
    removeProjectorTextChange, setTopOrBottom, setProfileDetails,
    setProjectorImage, setProjectorOwner, setUserConnections } = useContext(AppContext);
    useEffect(() => {
      setTopOrBottom(false);
    }, []);

  const { getToken } = useContext(AuthContext);
  const hasId1 = userConnections.some((item) => item.id === '1');
  const [removeProjectorText, setRemoveProjectorText] = useState(false);
  const [isOpenModal, setModal] = useState(false);
  const [isOpenPromoModal, setOpenPromoModal] = useState(true);
  const [isOpenMobileModal, setOpenMobileModal] = useState(true);
  const [showIcons, setshowIcons] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [selectedProjectorName, setProjectorName] = useState('');
  const [selectedProjectorId, setProjectorId] = useState(undefined);
  const [snackbarData, setSnackbar] = useState(defaultSnackbar);
  const dashboard = langText?.dashboard;
  const commonText = langText?.common;
  const [showArrow, setArrows] = useState(true);
  const [newOrder, updateNewOrder] = useState();
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(undefined);

  const apiUrl = `${baseUrl}/addTutorialsBack`;
  const token = getToken();

  const handlePostRequest = async () => {
    setIsLoading(true);
    try {
      const axiosResponse = await axios.post(apiUrl, { token });

      if (axiosResponse.status === 200) {
        setResponse(axiosResponse.data);
      } else {
        // Handle error, e.g., set an error state
      }
    } catch (error) {
      // Handle network or other errors
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const callForUpdate = () => {
    const apiUrl = `${baseUrl}/v1/loadUserContents`;
    const postData = {
      token: getToken()
    };
    axios.post(apiUrl, postData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('err', error);
      });
  };

  useEffect(() => {
    // callForUpdate();
    setNeedToShowETA(false);
    setHelpNeed(true);
  }, []);

  const [isBelowAccessButton, setBelowAccessButton] = useState(true);
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    });
  };
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('resize', setDimension);
    return (() => {
      window.removeEventListener('resize', setDimension);
    });
  }, [screenSize]);
  const [carouselKey, setCarouselKey] = useState(0);

  const forceUpdateCarousel = () => {
    // Incrementing the carouselKey value will trigger a re-render of the Carousel
    setCarouselKey((prevKey) => prevKey + 1);
  };
  useEffect(() => {
    if ((screenSize.dynamicWidth <= 767 && userConnections?.length > 1)
      || (screenSize.dynamicWidth >= 768 && screenSize.dynamicWidth <= 1199 && userConnections?.length > 2)
      || (screenSize.dynamicWidth >= 1200 && screenSize.dynamicWidth <= 1440 && userConnections?.length > 3)
      || (screenSize.dynamicWidth >= 1441 && screenSize.dynamicWidth <= 1709 && userConnections?.length >= 4)
      || ((screenSize.dynamicWidth >= 1710 && userConnections?.length > 4))) {
      setArrows(true);
    } else {
      setArrows(false);
    }
    //   if ((screenSize.dynamicWidth >= 768 && screenSize.dynamicWidth <= 1199 && userConnections?.length < 2)
    //   || (screenSize.dynamicWidth >= 1200 && screenSize.dynamicWidth <= 1440 && userConnections?.length < 3)
    //   || (screenSize.dynamicWidth >= 1441 && userConnections?.length < 4)) {
    //     setBelowAccessButton(false);
    //   } else {
    //     setBelowAccessButton(true);
    //   }
  }, [screenSize, userConnections]);

  // useEffect(() => {
  //   const userConnectionFetch = setInterval(() => {
  //     getUserConnections();
  //         }, 30000);
  //   return () => {
  //     clearInterval(userConnectionFetch);
  //   };
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      setOpenPromoModal(true);
    }, 1000);
  }, []);

  const ButtonGroupCarousel = ({ next, previous }) => (
    <ButtonGroup className={styles.customArrowButtonGroup}>
      <CustomRightArrow onClick={next} />
      <CustomLeftArrow onClick={previous} />
    </ButtonGroup>
  );
  const carouselRef = useRef(null);
  const goToFirstSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.goToSlide(0);
    }
  };
  useEffect(() => {
    goToFirstSlide();
  }, [userConnections]);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
const changedItems = {};
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const newItems = reorder(userConnections, result.source.index, result.destination.index);
    newItems?.forEach((item, idx) => {
      changedItems[item?.id] = idx + 1;
    });
    if (source.index !== destination.index) {
      updateNewOrder({ items: changedItems });
    }
    const reorderedConnections = userConnections.map((connection) => ({ ...connection }));
    const [removed] = reorderedConnections.splice(source.index, 1);
    reorderedConnections.splice(destination.index, 0, removed);
    setUserConnections(reorderedConnections);
  };
  const [isReorderd, setIsReorderd] = useState(false);
  const reorderApi = async () => {
    setIsReorderd(true);
    try {
      const myToken = await getToken();
      const apiUrl = `${baseUrl}/reorderProjectors`;
      const requestBody = {
        token: myToken,
        items: newOrder?.items
      };
      const response = await axios.post(apiUrl, requestBody);
      // setdata(response.data);
      setIsReorderd(false);
      setRemoveProjectorText(false);
    } catch (error) {
      console.error('Error:', error);
      setRemoveProjectorText(false);
      setIsReorderd(false);
    }
  };

  return (
    <Page
      className={styles.page}
      containerClassName={styles.pageContainer}
      contentClassName={styles.pageContent}
      noBottomBar
      // noDefaultBg
      noFixedSidebar
      transparentCompactHeader
      accessPageBackGround
    >
      <div className={styles.access}>
        <div className={styles.message}>
          {dashboard?.title || 'Choose a Projector to View'}
          {/* <>Choose a Projector to view</> */}
          {/* <sp
          n className={styles.messageSpace}>&nbsp;</span>
          <br className={styles.messageBreak} />
          <>to view</> */}
        </div>

        <div
          className={cn({
            [styles.profileLinksContainer]: true,
            [styles[`has${((userConnections?.length + 1) >= 4 ? 4
              : (userConnections?.length + (userDetails.totalContentUploaded > 0 ? 2 : 1)))}Item`]]: true,
            [styles.accessButtonBelow]: isBelowAccessButton
          })}
        >
          {!removeProjectorText
          && (
          <Box className={styles.carouselWrapper} position="relative" px="60px">
            {
              !loadingForAccess && (userConnections?.length || userDetails.totalContentUploaded > 0) ? (
                <Carousel
                  ref={carouselRef}
                  arrows
                  showDots={showArrow}
                  swipeable={showArrow}
                  draggable={showArrow}
                  responsive={responsive}
                  ssr={isTrueValue} // means to render carousel on server-side.
                  autoPlaySpeed={1000}
                  keyBoardControl={isTrueValue}
                  containerClass={`${styles.customContainerClass} ${!showArrow ? 'carousel-profile-container carousel-profile-container-center align-first' : 'carousel-profile-container'}`}
                  itemClass={`profile-item ${styles.profileItem}`}
                  dotListClass={styles.customDotListClass}
                  // customButtonGroup={showArrow ? <ButtonGroupCarousel /> : null}
                  renderButtonGroupOutside
                 customRightArrow={<CustomRightArrow />}
                 customLeftArrow={<CustomLeftArrow />}
                >
                  {userDetails.totalContentUploaded > 0
                    ? (
                      <ProfileLink
                        // className={styles.requestProfileAccess}
                        // dpURL={PersonIcon}
                        gender="common"
                        // isViewMyContent
                        setdataOfCategory={setdataOfCategory}
                        link={Pathname.getProfilePath()}
                        name={dashboard?.viewProjector || 'View my projector'}
                        dpURL={userDetails?.dpURL}
                      />
                    ) : null}
                    {userConnections
                    // ?.sort((a, b) => (a.id === '1' ? -1 : b.id === '1' ? 1 : 0))
                    .map(({ email, firstname, id, image }, idx) => (
                      <ProfileLink
                      removeProjectorTextChange={removeProjectorTextChange}
                        setdataOfCategory={setdataOfCategory}
                        key={idx}
                        link={Pathname.getFriendProfilePath(id)}
                        dpURL={image}
                        name={firstname || email}
                        id={id}
                        setProjectorOwner={setProjectorOwner}
                        setProfileDetails={setProfileDetails}
                        setProjectorImage={setProjectorImage}
                        showRemoveProjectorText={removeProjectorText}
                        setModalTitle={setModalTitle}
                        setModal={setModal}
                        onCancelButtonClick={setModal}
                        setProjectorName={setProjectorName}
                        setProjectorId={setProjectorId}
                        removeProjectorText={dashboard?.remove || 'Remove Projector'}
                      />
                    ))}

                  {
                    !isBelowAccessButton ? (
                      <ProfileLink
                        isBelowAccessButton={isBelowAccessButton}
                        className={styles.requestProfileAccess}
                        gender="male"
                        isAccessRequestButton
                        link={Pathname.requestAccess}
                        name={dashboard?.newRequest || 'Request New Access'}
                      />
                    ) : null
                  }
                </Carousel>
              ) : null
            }
          </Box>
          )}
{removeProjectorText && (
  <DragDropContext onDragEnd={handleDragEnd}>
    <Droppable droppableId="userConnections">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {userConnections
            // ?.sort((a, b) => (a.id === '1' ? -1 : b.id === '1' ? 1 : 0))
            // .filter(({ id }) => id !== '1')
            .map(({ email, firstname, lastname, id, image }, idx) => (
              <Draggable key={id} draggableId={id} index={idx}>
                {(draggableProvided) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    <ProfileLinkForDnd
                      draggableProvided={draggableProvided}
                      email={email}
                      lastname={lastname}
                      setProfileDetails={setProfileDetails}
                      setdataOfCategory={setdataOfCategory}
                      link={Pathname.getFriendProfilePath(id)}
                      dpURL={image}
                      name={firstname || email}
                      id={id}
                      setProjectorOwner={setProjectorOwner}
                      setProjectorImage={setProjectorImage}
                      showRemoveProjectorText={removeProjectorText}
                      setModalTitle={setModalTitle}
                      setModal={setModal}
                      onCancelButtonClick={setModal}
                      setProjectorName={setProjectorName}
                      setProjectorId={setProjectorId}
                      removeProjectorText={dashboard?.remove || 'Remove Projector'}
                    />
                  </div>
                )}
              </Draggable>
            ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
)}

          {
            isBelowAccessButton ? (
              <div className={styles.flexico}>
                <ProfileLink
                isBelowAccessButton={isBelowAccessButton}
                className={styles.requestProfileAccess}
                gender="male"
                isAccessRequestButton
                link={Pathname.requestAccess}
                name={dashboard?.newRequest || 'Request New Access'}
                />
                {removeProjectorText && hasId1 === false
                && (
                <ProfileLink
                isBelowAccessButton={isBelowAccessButton}
                className={isLoading === true ? styles.blockReq : styles.requestProfileAccess}
                gender="male"
                isAccessRequestButton
                onClick={isLoading === true ? null : handlePostRequest}
                // link={Pathname.requestAccess}
                name="Add Tutorials"
                />
                )}
              </div>
            ) : null
          }
        </div>
        <div className={styles.flexico}>
        {
          userConnections.length > 0 ? (
            <Button onClick={() => { setRemoveProjectorText(!removeProjectorText); }} className={styles.manageProjectorButton}>
              {removeProjectorText ? (dashboard?.cancel || 'Cancel') : (dashboard?.manageProjector || 'Manage Projectors')}
            </Button>
          ) : null
        }
        {
          removeProjectorText && (
            <Button onClick={() => reorderApi()} disabled={isReorderd} className={styles.manageProjectorButton}>
              {isReorderd ? 'Saving...' : 'Save'}
            </Button>
          )
        }
        </div>
        <div className={styles.bottomQuarterEcllipse}>
          <OvalBackground className={styles.ovalShape} />
        </div>
      </div>
      <Snackbar {...snackbarData} onClose={() => { setSnackbar({ ...snackbarData, isVisible: false }); }} />
      {
        isOpenModal && (
          <ConfirmationModal
            isVisible={isOpenModal}
            title={`Are you sure you want to remove ${selectedProjectorName}${selectedProjectorName?.trim() !== 'Tutorials' ? "'s projector" : ''}?`}
            onHide={() => { setModal(false); }}
            cancelButton={commonText?.goBack || 'Go Back'}
            continueButton={removeProjectorTextChange ? 'Removing' : 'Remove'}
            content={dashboard?.removeProjectorTitle || 'Once you remove a Projector you will have to request to view again.'}
            id={selectedProjectorId}
            name={selectedProjectorName}
            setSnackbar={setSnackbar}
          />
        )
      }
      {screenSize.dynamicWidth <= 500 && (
        <MobileModal
          isVisible={isOpenMobileModal}
          onHide={() => { setOpenMobileModal(false); }}
          showCloseButton
        />
      )}
      {
        isOpenPromoModal && location.state?.promocode !== undefined
        && location.state?.promocode !== null && (
          <PromotionModal
            isVisible={isOpenPromoModal}
            onHide={() => { setOpenPromoModal(false); }}
            promoCode={location.state?.promocode ? location.state.promocode.promo_code : ''}
            loginCode={location.state?.mobile_token ? location.state.mobile_token : ''}
            showCloseButton
            promoValue={location.state?.promocode ? location.state.promocode.promo_value : '3'}
          />
        )
      }
    </Page>
  );
};
