import AXIOS from 'axios';
import endpoints from './endpoints.json';
import baseUrl from './baseURL.json';
import firebaseCredentials from './firebaseCredentials.json';

const getToken = () => localStorage.getItem('token');
const AxiosCancelToken = AXIOS.CancelToken;

let selectedBaseUrl = baseUrl.PRODUCTION;

console.log('ORIGIN==>', window.location.origin);
if (window.location.origin.includes('localhost')) {
  selectedBaseUrl = baseUrl.PRODUCTION;
} else if (window.location.origin.includes('dev.app')) {
  selectedBaseUrl = baseUrl.DEVELOPMENT_URL;
} else if (window.location.origin.includes('preprod')) {
  selectedBaseUrl = baseUrl.PREPRODUCTION_URL;
}

const axios = AXIOS.create({ baseURL: selectedBaseUrl, method: 'POST' });
axios.interceptors.request.use((req) => {
  if (req?.data instanceof FormData) {
    const formData = req?.data;

    if (formData.has('token')) {
      formData.delete('token');
    }

    formData.append('token', getToken());

    return { ...req, data: formData };
  }

  return { ...req, data: { ...req.data, token: getToken() } };
});

const dataFetcher = (url, data, cancelToken) => axios({
    url,
    ...(data && { data }),
    ...(cancelToken && { cancelToken }),
  }).then((res) => res.data);

const getBaseUrl = () => selectedBaseUrl;
const apiBaseUrl = getBaseUrl();
export {
  axios,
  AxiosCancelToken,
  dataFetcher,
  endpoints,
  getToken,
  apiBaseUrl as baseUrl,
  firebaseCredentials
};
