import React from 'react';
import cn from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AlterImage from 'Assets/alter-image.png';
import Spinner from 'Components/Spinner';
import styles from '../index.module.css';

export const HeaderIconButton = ({ disabled, icon: Icon, uploaderLoader, text, onClick, link, setMouseOver, isMouseOver, iconType, selectedTabClass }) => (
  <Link className={styles.profileHeaderIconButton} onClick={onClick} to={link}>
    {/* {text === 'Watchlist' && <img src={Icon} className={styles.profileHeaderIconButtonIcon} /> } */}
    {Icon && <Icon onMouseEnter={() => setMouseOver(iconType)} onMouseLeave={() => setMouseOver(null)} className={cn({ [styles.profileHeaderIconButtonIcon]: true, [styles.iconMouseOver]: isMouseOver && isMouseOver === iconType, [styles.searchIconMouseOver]: isMouseOver === 'search' })} />}
    <button style={{ cursor: disabled && 'not-allowed', opacity: disabled && '.5' }} disabled={uploaderLoader || disabled} className={cn({ [styles.profileHeaderIconButtonText]: true, [styles.textMouseOver]: isMouseOver && isMouseOver === iconType, [styles.selectedTab]: selectedTabClass })} onMouseEnter={() => setMouseOver(iconType)} onMouseLeave={() => setMouseOver(null)}>
      {text}
      {uploaderLoader && <Spinner spinex="spinex" />}
    </button>
  </Link>
);
export const ProfileMenuProfileButton = ({ setProfileDetails, firstname, email, Icon, link, name, onClick, image, isPerson }) => (
  <Link
  className={styles.profileMenuProfile}
  onClick={() => {
    onClick();
    setProfileDetails({
      // eslint-disable-next-line
      firstname: firstname,
      // eslint-disable-next-line
      image: image
    });
  }}
  to={link}
  >

    {
      image ? (
        <img
          className={`${styles.profileMenuProfileIcon} ${isPerson && styles.border}`}
          src={image || AlterImage}
          onError={(e) => {
            e.target.src = AlterImage;
          }}
        />
      )
        : <Icon className={`${styles.profileMenuProfileIcon} ${isPerson && styles.border2}`} />
    }
    {/* : <Icon style={{color:black}} className={styles.profileMenuCurrentProfileDetailsIcon} />} */}

    <div className={styles.profileMenuProfileName}>{name || email}</div>
  </Link>
);

export const ProfileDrawerProfileButton = ({ email, Icon, link, name, onClick, image }) => (
  <Link className={styles.profileBarProfile} onClick={onClick} to={link}>
    <div style={{ overflow: 'hidden' }} className={styles.profileBarProfileIconContainer}>
      {image ? (
        <img
          style={{ width: '100%', height: '100%' }}
          // className={`${styles.profileMenuCurrentProfileDetailsIcon}`}
          src={image || AlterImage}
          onError={(e) => {
            e.target.src = AlterImage;
          }}
        />
      )
        : <Icon className={styles.profileBarProfileIcon} />}
    </div>
    <div className={styles.profileBarProfileName}>{name || email}</div>
  </Link>
);

// const ProfileDrawerProfileButton = ({ email, Icon, link, name, onClick, image }) => (
//   <Link className={styles.profileBarProfile} onClick={onClick} to={link}>
//     <div className={styles.profileBarProfileIconContainer}>
//       <Icon className={styles.profileBarProfileIcon} />
//     </div>
//     <div className={styles.profileBarProfileName}>{name || email}</div>
//   </Link>
// );
